<template>
  <b-container fluid>
    <my-table
      title="Voucher Logs"
      :rows="rows"
      :columns="columns"
      :pagination="pagination"
      :is-loading="isLoading"
    >
      <template v-slot="{props}">
        <span v-if="props.column.field === 'order_id'">
          <b-button
            :to="{ name: 'order-summary', params: { id: props.row.order_id } }"
            variant="danger"
          >
            {{ props.row.reference_id }}
          </b-button>
        </span>
      </template>
    </my-table>
  </b-container>
</template>

<script>
import { MyTable } from '@/components'
import axios from '@axios'

export default {
  name: 'VouchersLogs',
  components: {
    MyTable,
  },
  data() {
    return {
      columns: [
        {
          label: 'Date Applied',
          field: 'date_added',
          tdClass: 'align-middle',
        },
        {
          label: 'Order ID',
          field: 'order_id',
          thClass: 'align-middle text-left',
          tdClass: 'align-middle text-left',
          type: 'number',
        },
        {
          label: 'B2BPro User',
          field: 'company_name',
          tdClass: 'align-middle',
        },
        {
          label: 'Generated Voucher',
          field: 'voucher_code',
          tdClass: 'align-middle',
        },
      ],
      rows: [],
      pagination: {
        enabled: true,
        mode: 'records',
        perPage: 5,
        perPageDropdown: [5, 10, 15],
      },
      isLoading: false,
    }
  },
  created() {
    this.fetchOrderVoucherLogs()
  },
  methods: {
    async fetchOrderVoucherLogs() {
      this.isLoading = true
      const response = await axios.get('/admin/vouchers/order-logs')
      if (response.status === 200) {
        this.rows = response.data.data
      }
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" >
  @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
